@import 'color';

::selection {
  background: rgba(27, 156, 252, 0.99);
  color: white;
}

::-moz-selection {
  background: rgba(27, 156, 252, 0.99);
  color: white;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue;
  background-color: var(--light-blue-color);
  color: var(--dark-gray-color) !important;
}

.background-light {
  background-color: var(--light-blue-color) !important;
}

h1, h2, h3, h4, h5, h6, p {
  cursor: default;
}

button:focus {
  outline: 0 !important;
}

/* Fonts */
@font-face {
  font-family: "adelle";
  src: url("https://use.typekit.net/af/01fb88/00000000000000003b9af921/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/01fb88/00000000000000003b9af921/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/01fb88/00000000000000003b9af921/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

.fontMido {
  font-family: "adelle", serif;
}

.fontCode {
  font-family: 'Inconsolata', monospace;
}

.centerParagraph {
  text-align: center;
  max-width: 800px;
  margin: auto;
}

h1 { font-size: 6rem; }
h2 { font-size: 3.75rem; }
h3 { font-size: 3rem; }
h4 { font-size: 2.125rem; }
h5 { font-size: 1.5rem; }
h6 { font-size: 1.25rem; }
p, span { font-size: 1rem; }

/* Links */
.link {
  color: var(--primary-color) !important;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Buttons */
.button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.button:hover:not([disabled]) {
  background-color: var(--primary-dark-color);
  color: var(--white-color);
  text-decoration: none;
  box-shadow: none;
}

.button:focus {
  outline: 0;
}

.buttonCancel {
  background-color: var(--red-color);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
}

.buttonCancel:hover:not([disabled]) {
  color: var(--white-color) !important;
  text-decoration: none;
  background-color: var(--red-dark-color);
  box-shadow: none;
}

.buttonSecondary {
  background-color: var(--gray-color);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
}

.buttonSecondary:hover:not([disabled]) {
  background-color: var(--dark-gray-color);
  box-shadow: none;
}

[data-theme="dark"] .buttonSecondary {
  background-color: var(--light-gray-color);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
}

[data-theme="dark"] .buttonSecondary:hover:not([disabled]) {
  background-color: var(--gray-color);
  box-shadow: none;
}

button:disabled {
  opacity: 0.65;
  cursor: default;
}

.buttonRounded {
  display: block;
  margin: 0 auto;
  padding: 7px;
  padding-left: 25px;
  padding-right: 25px;
  width: fit-content;
  border-radius: 50px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--white-color);
  font-size: 1.4rem;
  border: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
}

.buttonRounded:hover:not([disabled]) {
  text-decoration: none;
  background-color: var(--primary-dark-color);
  box-shadow: none;
  color: white;
}

/* TODO: checkboxes */

/* other Form items */
[data-theme="dark"] textarea, [data-theme="dark"] textarea:focus {
  background-color: #000;
  color: var(--gray-color);
  border-color: var(--dark-gray-color);
}

[data-theme="dark"] input, [data-theme="dark"] input:focus {
  background-color: #000;
  color: var(--gray-color);
  border-color: var(--dark-gray-color);
}

[data-theme="dark"] input:disabled, [data-theme="dark"] input[readonly] {
  background-color: #000;
  color: var(--light-gray-color);
  border-color: var(--gray-color);
}

[data-theme="dark"] .custom-range {
  background-color: transparent;
}

/* Tiles */
.tile {
  background-color: var(--white-black-color);
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.tileHead {
  margin-bottom: 0;
  font-weight: 500;
}

.tileSubtitle {
  color: var(--gray-color);
  margin-top: 0;
  margin-left: 5px;
  font-weight: 400;
  font-size: 1rem;
}

.tileSmallSubtitle {
  color: var(--gray-color);
  margin-top: -2px;
  margin-left: 6px;
  font-weight: 400;
  font-size: 0.7rem;
}

.tileParagraph {
  margin-top: 0;
  margin-left: 15px;
  font-weight: 400;
}

.shadow {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
}

/* Colors */
.primeText {
  color: var(--primary-color) !important;
}

.primeBackground {
  background-color: var(--primary-color) !important;
}

.primeBorder {
  border-color: var(--primary-color) !important;
}

.redText {
  color: var(--red-color) !important;
}

.redBackground {
  background-color: var(--red-color) !important;
}

.yellowText {
  color: var(--yellow-color) !important;
}

.yellowBackground {
  background-color: var(--yellow-color) !important;
}

.greenText {
  color: var(--green-color) !important;
}

.greenBackground {
  background-color: var(--green-color) !important;
}

.grayText {
  color: var(--gray-color) !important;
}

.grayBackground {
  background-color: var(--gray-color) !important;
}

/* hr's */
.thin-hr-white {
  background-color: var(--white-black-color);
  border: var(--white-color);
  height: 1px;
}

.thin-hr-dark {
  background-color: var(--dark-gray-color);
  border: var(--dark-gray-color);
  height: 1px;
}

.thin-hr-gray {
  background-color: var(--gray-color);
  border: var(--gray-color);
  height: 1px;
}

.thin-hr-prime {
  background-color: var(--primary-color);
  border: var(--primary-color);
  height: 1px;
}

.medium-hr-white {
  background-color: var(--white-black-color);
  border: var(--white-color);
  height: 2px;
}

.medium-hr-dark {
  background-color: var(--dark-gray-color);
  border: var(--dark-gray-color);
  height: 2px;
}

.medium-hr-gray {
  background-color: var(--gray-color);
  border: var(--gray-color);
  height: 2px;
}

.medium-hr-prime {
  background-color: var(--primary-color);
  border: var(--primary-color);
  height: 2px;
}

.thick-hr-white {
  background-color: var(--white-black-color);
  border: var(--white-color);
  height: 3px;
}

.thick-hr-dark {
  background-color: var(--dark-gray-color);
  border: var(--dark-gray-color);
  height: 3px;
}

.thick-hr-gray {
  background-color: var(--gray-color);
  border: var(--gray-color);
  height: 3px;
}

.thick-hr-prime {
  background-color: var(--primary-color);
  border: var(--primary-color);
  height: 3px;
}

.hr-80 {
  margin-left: 10%;
  margin-right: 10%;
}

.hr-60 {
  margin-left: 20%;
  margin-right: 20%;
}

/* Titles */
.titleSection {
  text-align: center;
}

/* Dark mode */
[data-theme="dark"] #darkToggle img {
  content: url(https://projekt-wahl.de/images/darkModeMoon.svg);
}

#darkToggle img {
  content: url(https://projekt-wahl.de/images/darkModeSun.svg);
}
