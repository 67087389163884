:root {
  --primary-color: #1b9cfc;
  --primary-dark-color: #198be3;
  --red-color: #fd3a35;
  --red-dark-color: #e33630;
  --orange-color: orange;
  --yellow-color: #ffd32a;
  --light-gray-color: #b9bec4;
  --gray-color: #525253;
  --dark-gray-color: #262626;
  --white-color: #fff;
  --white-black-color: #fff;
  --black-white-color: #000;
  --light-blue-color: #ebf2f4;
  --green-color: #02b03e;
  --green-dark-color: #029636;
  --dusk-color: #293442;
  --table-stripes-color: #f3f7f9;
}

/* dark mode color variables */
[data-theme="dark"] {
  --primary-color: #1b9cfc;
  --primary-dark-color: #198be3;
  --red-color: #fd3a35;
  --red-dark-color: #e33630;
  --orange-color: orange;
  --yellow-color: #ffd32a;
  --light-gray-color: #525253;
  --gray-color: #c3c3c3;
  --dark-gray-color: #fff;
  --white-color: #fff;
  --white-black-color: #262626;
  --black-white-color: #fff;
  --light-blue-color: #000;
  --green-color: #02b03e;
  --green-dark-color: #029636;
  --dusk-color: #293442;
  --table-stripes-color: #0d0d0d;
}
